@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600&display=swap");

tr[aria-level="2"] td:nth-last-child(1),
tr[aria-level="3"] td:nth-last-child(1) {
  color: rgb(17, 112, 255) !important;
  text-decoration: underline;
}

@font-face {
  font-family: "Satoshi";
  src: url("../Fonts/Satoshi_Complete/Satoshi_Complete/Fonts/Variable/Satoshi-Variable.ttf");
}

body {
  font-size: 14px !important;
  font-family: "Satoshi" !important;
  background-color: #F3F4F6 !important;
}

.p-treetable {
  font-size: 14px !important;
}

th {
  font-size: 14px !important;
  color: #6B7280;
  font-weight: 700;
  height: 40px;
  padding: 10px 10px 8px 20px;
  background-color: #f7f7f7;
}
/* .p-treetable-thead .firstColumn{
  padding-left: 70px;
} */
 .p-column-header-content{
  width: 100%;
 }

td {
  color: #111827;
  font-weight: 500;

}
.p-dropdown-items{

}

th,
td {
  font-family: "Satoshi" !important;
  margin: 0px !important;
  border: 1px solid #E5E7EB !important;

  /* border-width: 1px, 1px, 1px, 0px;
  border-style: solid;
  border-color: #E5E7EB;  */
}

.p-treetable-frozen-view .p-treetable-scrollable-body {
  border-right: 2px solid #E5E7EB;
}

.p-treetable-toggler {
  width: 1rem !important;
  height: 1rem !important;
}

.p-icon.p-treetable-toggler-icon {
  height: 11px !important;
}

.p-sortable-column-icon {
  height: 11px !important;
}

.firstColumn {
  display: flex;
  align-items: center;
  position: relative;
  border-left: none !important;
  padding-left: 20px ;
  border-right: 2px solid #E5E7EB !important;
  border: 1px solid #E5E7EB;

}

.p-treetable-scrollable-wrapper {
  position: static !important;
}

.p-treetable .p-treetable-thead>tr>th {
  border: 1px solid #E5E7EB;
}

.p-checkbox .p-checkbox-box {
  height: 16px !important;
  width: 16px !important;
  border-width: 1px;
  background-color: #F3F4F6;
  border-radius: 4px;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler+.p-checkbox {
  align-items: center !important;
}

tr[aria-level="2"]>td .p-treetable-toggler+.p-checkbox,
tr[aria-level="3"]>td .p-treetable-toggler+.p-checkbox {
  display: none !important;
}

tr[aria-level="2"]>td img,
tr[aria-level="3"]>td img {
  display: none !important;
}

tr[aria-level="1"]>td {
  background-color: #f3f4f6;
  height: 76.5px !important;
  padding: 10px 20px;
}
tr[aria-level="2"]>td,
tr[aria-level="3"]>td {
  background-color:  #FAFAFA;
  padding: 10px 20px;
  height: 45px;
}
tr[aria-level="2"]>td input,
tr[aria-level="3"]>td input {
display: none;
}
tr[aria-level="3"]>td{
  background-color: #f3f4f6 !important;
}
.p-treetable-scrollable-body-table .p-treetable-tbody tr[aria-level="1"]>{
  height: 76.5px !important;
}

tr[aria-level="1"]>td  .p-treetable-toggler{
position: absolute;
top: 41%;
left:40px
}




.tags {
  /* border: 1px solid rgb(184, 255, 184); */
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #E1FCE7;
  color: #237804;
  font-weight: 700;
  font-size: 12px;

}

.addtags {
  border: 1px solid #237804;
  padding: 4px 4px 5px 4px !important;
  border-radius: 4px;
  margin-right: 8px;
  background-color: white;
  color: #237804;
  font-weight: 700;
  font-size: 12px;
  height: 27px;
}
.addtags:hover{
  cursor: pointer;
}
.tags svg:hover{
  cursor: pointer;
}

.pi-plus {
  border: 1px solid #237804;
height: 20px;
width: 20px;
  border-radius: 50%;
  font-size: 10px;
  align-content: center;
 
}

.p-treetable .p-sortable-column.p-highlight {
  background: #E1FCE7 !important;
  color: #237804 !important;
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #237804 !important;
}



.p-paginator .p-paginator-pages .p-paginator-page,
.paginator .p-paginator-next,
.paginator .p-paginator-prev {
  height: 2rem;
  min-width: 2rem !important;
  border-radius: 5px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {

  background: #237804;
  border-color: #eef2ff;
  color: #ffffff;
  height: 24px;
  width: 24px;
  padding: 4px 3px 5px 4px;
  font-size: 12px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background: #F3F4F6;
  border-color: #eef2ff;
  color: #4B5563;

}

.p-paginator .p-paginator-last,
.p-paginator .p-paginator-first {
  display: none;
}

.p-paginator {
  padding: 12px 12px;
  height: 48px;
}

.p-paginator .p-paginator-next,
.p-paginator .p-paginator-prev {
  height: 24px;
  width: 24px;
  padding: 4px 3px 5px 4px;
  min-width: 2rem !important;
  border-radius: 5px;
  background: #F3F4F6;
  border-color: #eef2ff;
  /* color: #4B5563; */
}

.p-dropdown .p-dropdown-trigger {
  width: 1.5rem;
}

.p-dropdown .p-dropdown-label {
  padding: 4px 7px 4px 7px;
  align-content: center;
  font-size: 12px;

}

.p-dropdown-panel .p-dropdown-items {
  font-size: 12px;
}

.p-dropdown:hover,
.p-dropdown:focus,
.p-inputtext.p-inputtext-sm:hover,
.p-inputtext.p-inputtext-sm:focus {
  border-color: #237804;
  box-shadow: none !important;
}

.target .p-dropdown,
.p-inputtext.p-inputtext-sm {
  box-shadow: none !important;
  border-color: #d1d5db;
  background-color: #F3F4F6;
}

.p-inputtext.p-inputtext-sm{
  font-size: 14px;
  height: 39px;
  font-weight: 500;
  color: #111827;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #237804;
  background: rgb(184, 255, 184);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  color: #6B7280;

}
.p-sortable-column-icon{
  display: none;
}

.p-treetable .p-treetable-thead>tr>th:hover .p-sortable-column-icon

{
  display: inline-block;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon{
  display: inline-block;
}



.pagination {
  box-shadow: 0px -4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  color: #6B7280;
  font-size: 12px;
}

.pagination-dropdown {}

.background-Changes {
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 20px;
  min-height: 300px;
  max-width: fit-content;

}
.p-checkbox.p-highlight .p-checkbox-box {
  border-color: #237804;
  background: #237804;
}
.p-checkbox.p-highlight .p-checkbox-box svg{
  color: white;
  font-weight: 800;
  height: 12px;
  width: 12px;
}

.p-checkbox.p-component:hover .p-checkbox-box {
  border-color: #237804; 
}

.modal.show .modal-dialog {
  max-width: 650px;
  height: 723px !important;
}
.modal-header{
  height: 50px;


}
.modal-title {
  font-size: 20px !important;
  color: #111827 !important;
  font-weight: 700 !important;
}
.modal-header .btn-close{
  font-size: 12px;
}

.search svg{
  position: absolute;
  top: 7px;
  left: 13px;
  /* height: 20px;
  width: 20px; */
}
.search input{
padding-left: 40px;
align-content: center;

}
.tags-box{
  border-radius: 4px;
  border: 1px solid #D1D5DB;
  padding: 13px 10px 10px 10px;
}
.tags-input{
  font-size: 16px !important;
}
.addtagbutton{
  padding: 5px 12px 6px 12px;
  background-color: #CAFCD0;
  color: #237804;
  font-weight: 700;
  border: none !important;
}


.ant-notification-notice {
  
}
.ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
  padding: 15px !important;
  height: 65px !important;
}
.ant-notification-notice-description{
  margin-top: 4px;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-closable .ant-notification-notice-message{
  margin: 0px !important;
}
.ant-notification-notice-close-icon{
color: #111827 !important;
}